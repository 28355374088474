import Graduation from '../media/Klawe.jpg';
import newsitem_pic from '../media/newsitem_pic.jpg';
import Regenstrief from '../media/Regenstrief.png';
import HMC from '../media/HMC.png';
import Dodds from '../media/Graduation.jpg';

export const news_items = [
  {
    pic: Regenstrief,
    year: 2024,
    text_p: <p>Received the <a href="https://www.purdue.edu/research/rche/students/student-support.php">John W. Anderson Foundation</a> scholarship, which supports researchers interested in healthcare engineering.</p>
  },
  {
    pic: Graduation,
    year: 2023,
    text_p: <p>Graduated from HMC with high distinction and honors. Earned the <a href="https://www.hmc.edu/cs/student-awards/">Class of '94</a> award, the CS department's top award, and the RLCIE Fellow designation.</p>
  },
  {
    pic: Dodds,
    year: 2021,
    text_p: <p>Received the <a href="https://www.hmc.edu/about/2024/05/01/celebrating-hmc-community-leaders/#:~:text=Dean%20Sundberg%20Prize,-Ethan%20Sandoval%20'26&text=Established%20by%20Dana%20Seaton%20'06,impact%20on%20the%20College%20community.">Dean Chris Sundberg Prize</a>, recognizing a HMC junior who demonstrates exceptional leadership and has a positive impact on HMC community.</p>
  },

];